.header {
  height: $header-height;
  border-bottom: solid 1px $white-2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-left: 84px;
  padding-right: 52px;
  width: 100%;
}

.button-logout {
  width: 118px;
}

.hourly-header-icon {
  height: 44px;
  width: 164px;
}

.user-container {
  font-family: Roboto;
  font-size: $font-size-md;
  font-weight: normal;
  color: $greyish-brown;
}

.user-header-avatar {
  height: 50px;
  width: 50px;
  border: solid 1px $white;
}

.edit-profile {
  text-decoration: none;
  color: $greyish-brown;
  font-size: $font-size-sm;

  &:hover {
    text-decoration: underline;
  }
}
