.side-nav {
  display: flex;
  height: calc(100vh - 200px);
  padding: 50px 12px;
  flex-direction: column;
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    text-decoration: none;

    &__icon {
      color: $silver;
      height: 28px;
      width: 28px;
    }

    &__title {
      margin-top: 4px;
      color: $silver;
      font-size: 12px;
      text-decoration: none;
    }

    &--active {
      box-shadow: 0 19px 20px -12px rgba(0, 0, 0, 0.13);
      border: solid 0.1px $gallery;
      background-color: $white;

      .side-nav__item__icon {
        color: $azul;
      }

      .side-nav__item__title {
        color: $azul;
      }
    }
  }
}

.side-nav-icon {
  color: $silver;
  height: 28px;
  width: 28px;
}
