.circle-wrapper {
  border-radius: 50%;
  background-color: $primary-green;
  display: flex;
  justify-content: center;
  align-items: center;

  .circle-small {
    border-radius: 50%;
    background-color: $white-50;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
