// .table-container {
//   height: calc(100% - 100px);
//   overflow-y: auto;
// }

.approved {
  color: green;
}

.denied {
  color: red;
}

.pending {
  color: $azul;
}

.cancelled {
  color: $red-fire;
}

.hourly-table {
  width: 100%;
  border-spacing: 0;

  & thead {
    // top: 0;
    // position: static;
    line-height: 2;
    background-color: $white-50;

    & th:first-child {
      padding-left: 10px !important;
    }

    & .table-head-column {
      // top: 0;
      // position: sticky;
      background-color: $table-grey;

      font-weight: bold;
      font-size: $font-size-sm2;

      & .table-head-column-content {
        height: auto;
        min-height: 30px;
        padding: 16px 8px 16px 0px;

        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .table-body-row {
    font-weight: normal;
    background-color: $white;
    font-size: $font-size-sm2;

    & td:first-child {
      padding-left: 10px;
    }

    & td {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    & td:last-child {
      padding-right: 18px;
    }

    &.dark {
      background-color: $table-grey;
    }

    &.light {
      background-color: $white;
    }
  }
}
