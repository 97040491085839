.add-new-deal {
  padding: 52px 0px 40px 68px;
  background-color: $white-50;
}

.claim-offer {
  border-radius: 10px;
  padding: 8px 22px 22px 22px;
  background-color: $white;
  box-shadow: -10px 10px 20px 0 $black-5;

  &__title {
    display: flex;
    justify-content: center;
    color: $azul;
    font-size: $font-size-sm1;
    padding-bottom: 14px;
    margin-bottom: 14px;
    font-weight: 500;
    border-bottom: solid 1px $white-3;
  }

  &__info {
    font-size: $font-size-sm1;
    text-align: center;
    font-weight: normal;
  }
}

.claim-offer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);

  &--active {
    color: $azul;
  }

  &__title {
    margin: 6px 0px 4px 0px;
    font-size: $font-size-sm;
  }

  &__icon {
    width: 44px;
    height: 44px;
  }
}

.deal-stats-info {
  display: flex;
  border-radius: 10px;
  box-shadow: -10px 10px 20px 0 $black-5;
  background-color: $white;
  padding: 32px 28px;
}

.deal-stats-item {
  display: flex;
  flex-direction: column;

  &__title {
    color: $greyish-brown;
    font-size: $font-size-sm1;
    font-weight: 500;
  }

  &__value {
    font-size: $font-size-lg;
    font-weight: 500;
    color: $azul;
  }
}

.vertical-line {
  width: 1px;
  border-right: solid 0.5px $white-50;
  margin: -16px 16px;
}

.submit-btn {
  box-shadow: 0 18px 20px -10px $azul;
  background-color: $azul !important;
  color: $white !important;
  font-weight: 500;
}

.topup-btn {
  font-weight: 500;
}
