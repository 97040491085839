.google-map-viewer {
  width: 100%;
  border: none;
  height: 260px;
}

.place-search-map-placeholder {
  width: 100%;
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
