.text-input {
  &__label {
    color: $greyish-brown;
    font-size: $font-size-sm1;
    margin-left: 2px;
    margin-bottom: 4px;
  }

  &__info {
    margin-top: 4px;
    font-size: $font-size-sm;
    color: $red-fire;
  }

  &__input {
    position: relative;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 $black-5, 0 6px 12px 0 $black-5;
    border: solid 0.5px $black-5;
    background-color: $white;

    &__icon {
      position: absolute;
      color: $manatee;
      top: 12px;
      left: 12px;
      width: 14px;
      height: 14px;
    }

    &__down-icon {
      position: absolute;
      color: $manatee;
      top: 12px;
      right: 12px;
      width: 14px;
      height: 14px;
    }

    .select {
      border-radius: 4px;
      color: $windows-blue;
      box-shadow: 0 1px 2px 0 $black-5, 0 6px 12px 0 $black-5;
      border: solid 0.5px $black-5;
      width: 100%;
      padding: 10px 16px 10px 36px;
      font-size: $font-size-sm1;
      outline: none;
      background-color: $white;

      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }

    .select::placeholder {
      color: $windows-blue;
    }

    &--no-icon {
      .select {
        padding-left: 16px;
      }
    }
  }
}
