.edit-profile__minw-250px {
  min-width: 250px;
}

.add-billing {
  @extend .add-location;
}

.label-billing {
  text-transform: capitalize;
}
