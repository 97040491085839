.btn {
  padding: 8px 0;
  display: inline-block;
  outline: none;
  font-size: $font-size-sm1;
  color: $azul;
  border-radius: 24px;
  border: solid 0.3px $azul;
  background-color: $white;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  transition: color 0.15s;
  cursor: pointer;
}

.link-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.link-button:focus {
  outline: none;
  cursor: pointer;
}

.btn-disabled {
  opacity: 0.6;
  cursor: default;
}
