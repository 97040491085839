.support-link {
  text-decoration: none;
  display: flex;
  color: $greyish-brown;
  font-weight: 500;
  font-size: $font-size-sm1;
  align-items: center;

  &__icon {
    width: 8px;
    height: 8px;
    margin-right: 4px;
  }
}

.support-link:hover {
  text-decoration: none;
  color: $greyish-brown;
}
