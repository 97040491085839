// Font Size Loop 10 to 50 Even Numbers
@for $size from 10 through 50 {
  @if $size %2==0 {
    .fs-#{$size}px {
      font-size: $size + px !important;
    }
  }
}

// Font Weight Loop 300 - 800
@for $size from 3 through 8 {
  .fw-#{$size}00 {
    font-weight: $size * 100 !important;
  }
}

$alignments: center, left, right, justify;

@each $alignment in $alignments {
  .text-align-#{$alignment} {
    text-align: $alignment !important;
  }
}

$transforms: none, lowercase, uppercase, capitalize;

@each $transform in $transforms {
  .text-transform-#{$transform} {
    text-transform: $transform !important;
  }
}
