.location-info {
  font-size: $font-size-sm2;
  padding: 12px 12px;
}

.add-location {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: $white;
  font-size: $font-size-lg;
  background-image: linear-gradient(46deg, $azul, $windows-blue 51%, $sea-green 98%);
}
