.wrapper {
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid $manatee;

  .upload__info {
    border-top: 1px dotted $manatee;
  }

  .upload__view {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  button {
    background-color: $silver;
    border: none;
    color: $white-50;
    padding: 6px 10px;
    border-radius: 4px;
    margin-top: 12px;
  }

  img {
    width: 100%;
    height: 100%;
    max-width: 640px;
    max-height: 360px;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.layer {
  opacity: 0.4;
  background-color: $manatee;
}
