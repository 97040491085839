.login-info {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  &__last-login-date-time {
    font-size: $font-size-sm;
    color: $warm-grey;
    font-weight: bold;
    margin-bottom: 4px;
  }

  &__last-login-relative-time {
    font-size: $font-size-sm1;
    color: $azul;
    font-weight: bold;
  }
}
