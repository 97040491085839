*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.15;
  font-family: 'Roboto', sans-serif;
}

.app-content-container {
  background: linear-gradient(to right, $white 50%, $black-5 50%);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - #{$header-height} - #{$footer-height});
}

.app-content {
  padding: 48px 52px;
  height: 100%;
  overflow-y: auto;

  display: flex;
  flex-direction: row;
  width: 100%;

  &__main {
    background-color: $white-50;
    height: calc(100vh - #{$header-height} - #{$footer-height});
    flex-grow: 1;
  }
}
