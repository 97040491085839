.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

// .d-flex {
//   display: -ms-flexbox !important;
//   display: flex !important;
// }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

// Width Sizes from 10 to 100
@for $size from 1 through 10 {
  .w-#{$size}0 {
    width: $size * 10% !important;
  }
}

@for $size from 0 through 4 {
  .border-radius-#{$size*25} {
    border-radius: $size * 25% !important;
  }
}
