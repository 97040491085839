.login-container {
  background-image: linear-gradient(56deg, $azul, $windows-blue 52%, $sea-green);
  padding: 2rem 0;
  height: calc(100vh - #{$header-height} - #{$footer-height});
}

.login-card {
  display: flex;
  padding: 56px 48px;
  border-radius: 10px;
  background-color: $white;
  max-width: 860px;
  margin: 0 auto;
  &__vertical-border {
    width: 1px;
    border-right: solid 0.5px $warm-grey;
    margin: 0 44px;
  }
}

.login-card-text {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 16px;

  &__title {
    font-size: $font-size-md;
    color: $azul;
    margin-bottom: 16px;
  }

  &__sub-title {
    font-size: $font-size-md;
    color: $warm-grey;
  }
}

.login-form-container {
  &__title {
    font-family: 'Roboto';
    font-size: 30px;
    color: $azul;
    font-weight: 900;
  }

  &__sub-title {
    font-size: $font-size-sm2;
    color: $warm-grey;
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  font-size: $font-size-sm;
  color: $warm-grey;
  margin: 0px;
}

.forgot-password-link {
  color: $warm-grey;
  font-size: $font-size-sm;
}

.button-sign-in {
  width: 146px;
  font-size: $font-size-sm2;
  color: $azul;
  background-color: $white;
  font-weight: 500 !important;
}

.button-login {
  width: 146px;
  font-size: $font-size-sm2;
  box-shadow: 0 18px 21px -10px rgba(57, 163, 202, 0.5);
  background-image: linear-gradient(78deg, $azul, $windows-blue 51%, $sea-green 100%);
  border: none;
  color: $white;
}
