.edit-profile__user-avatar {
  width: 118px;
  height: 118px;
}

.edit-profile__main-button {
  border: none;
  outline: none;
  border-radius: 16px;
  background-color: #44d7b6;
  padding: 6px 41px 7px 42px;

  &:focus {
    opacity: 0.7;
  }

  &:hover {
    opacity: 0.8;
  }
}
